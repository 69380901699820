<template>
  <SectionWithBg
    growing-body
    single-line-heading
    heading-position="left"
    class="mt-20"
  >
    <template #heading> Apply to {{ workshopTitle }}</template>
    <template #body>
      <div class="w-100">
        <transition name="fade">
          <FormSuccess v-if="regSuccess" class="mx-10">
            Thank you for applying! We will respond you as soon as possible!
            <Link
              :link="{
                name: 'Workshop',
                params: {
                  id: $route.params.id,
                  workshop: workshop.id,
                },
              }"
              class="mt-5"
            >
              Return to workshop page
            </Link>
          </FormSuccess>
        </transition>
        <transition name="fade">
          <form v-if="!regSuccess" @submit.prevent="submitRegistration">
            <Input
              v-for="(field, name) in form"
              :key="name"
              v-model="field.value"
              :error="field.error"
              :name="name"
              :label="field.label"
              :is-multiline="field.isMultiline"
              type="text"
              @input="clearError(name, form)"
              @blur="validateField(name, form)"
            />
            <Preloader :loading="loading" />
            <Button class="contact-us__button mt-4 mb-2"> apply</Button>
            <transition name="fade">
              <Error v-if="regError" class="register__error">
                <template #heading
                  >{{ regError || "Sorry, something went wrong" }}
                </template>
                <template #body>
                  Please try again or contact us at
                  <a
                    :href="`mailto:${config.helpEmail}?subject=Workshop Register Error`"
                    class="error__link"
                  >
                    {{ config.helpEmail }}
                  </a>
                </template>
              </Error>
            </transition>
          </form>
        </transition>
      </div>
    </template>
  </SectionWithBg>
</template>

<script>
import Preloader from "@/components/common/Preloader";
import SectionWithBg from "@/components/common/SectionWithBg";
import Input from "@/components/common/Input";
import Button from "@/components/common/Button";
import Link from "@/components/common/Link";
import Error from "@/components/common/Error";
import FormSuccess from "@/components/common/FormSuccess";
import {
  validateField,
  validateForm,
  clearError,
} from "@/assets/js/validation";
import { transformForm } from "@/assets/js/utils";
import config from "../../../public/config.json";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "WorkshopReg",
  props: {},
  components: {
    Link,
    Preloader,
    FormSuccess,
    SectionWithBg,
    Input,
    Button,
    Error,
  },
  data: () => ({
    config,
    loading: false,
    regError: null,
    regSuccess: false,
    successId: null,
    form: {
      first_name: {
        value: "",
        error: "",
        rules: ["required"],
        label: "First Name",
      },
      last_name: {
        value: "",
        error: "",
        rules: ["required"],
        label: "Last Name",
      },
      email: {
        value: "",
        error: "",
        rules: ["required", "email"],
        label: "Email",
      },
      organization: {
        value: "",
        error: "",
        rules: ["required"],
        label: "Organization",
      },
      job_title: {
        value: "",
        error: "",
        rules: ["required"],
        label: "Job Title",
      },
    },
  }),
  mounted() {},
  computed: {
    ...mapGetters({
      workshopTitle: "getWorkshopTitle",
    }),
  },
  methods: {
    transformForm,
    validateField,
    validateForm,
    clearError,
    ...mapActions(["POST", "GET"]),
    submitRegistration() {
      this.loading = true;
      this.regError = null;
      const isValid = validateForm(this.form);
      if (!isValid) return;
      const formData = this.transformForm(this.form);
      console.log(formData);

      this.POST({
        route: `/public/workshop/${this.$route.params.workshop}/participant`,
        data: formData,
      })
        .then((res) => {
          this.successId = res.data.id;
          this.loading = false;
          this.regSuccess = true;
          this.$store.commit("resetWorkshopState");
        })
        .catch((err) => {
          this.regError = err.response.data.msg;
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss"></style>
